function UploadController(Uploader, jobSubmit, userTokens, $log, $mdDialog, $scope, $q, $rootScope) {
  this.buttonText = 'Drop File or Click';

  // Uploader.files = [];
  this.uploader = Uploader;
  // this.showPairButton = false;
  // this.showStitchButton = false;
  // this.showCheckbox = false;
  // this.selectAllChecked = false;
  this.linkedFiles = [];
  // this.stitchedGeneticFiles = Uploader.stitchedGeneticFiles;
  // this.isStitched = false;
  this.isPaired = false;

  this.submitted = [];

  this.allCancelled = false;

  const fragLabel = 'FragPipe File';

  function handleJobSubmit(jobResponse, file) {
    return jobSubmit.submitAsync(jobResponse).then(submittedJob => {
      file.submitted = true;
      file.jobId = submittedJob._id;

      return submittedJob;
    });
  }

  function uploadRemoteFile(remoteFile, jobData) {
    return Uploader.uploadCombinedRemoteFiles([remoteFile], jobData)
      .then(pjob => handleJobSubmit(pjob, remoteFile));
  }

  function uploadLocalFile(localFile, uuid, jobData) {
    return Uploader.uploadLocalFile(localFile, uuid, jobData)
      .then(pjob => handleJobSubmit(pjob, localFile));
  }

  function isFragPipeFile(header) {
    const fragPipeHeaders = ["Index", "NumberPSM", "ProteinID", "MaxPepProb", "ReferenceIntensity"];
    return fragPipeHeaders.every(field => header.includes(field));
  }

  function readFile(file) {
    return new Promise((resolve, reject) => {
      const stream = file.stream();
      const reader = stream.getReader();
      let decoder = new TextDecoder("utf-8");
      let result = '';

      function processChunk({ done, value }) {
        if (done) {
          resolve(result);
          return;
        }

        result += decoder.decode(value, { stream: true });

        const newlineIndex = result.indexOf('\n');
        if (newlineIndex !== -1) {
          resolve(result.substring(0, newlineIndex));
          reader.cancel();
        } else {
          reader.read().then(processChunk).catch(reject);
        }
      }

      reader.read().then(processChunk).catch(reject);
    });
  }

  function labelRemoteFile(file) {
    const isGeneticFile = fileRegex.test(file.name.toLowerCase());
    return isGeneticFile ? "Genetic File" : "Unknown Type";
  }

  this.queueAdditionalFiles = (files) => {
    Uploader.files = Uploader.files.concat(files);

    this.handleFileSelection();
  };

  //TODO: include any type of compression extestion for .vcf .snp, Regex
  const fileRegex = /\.(vcf|snp)(\.\w+)?$/i;

  this.checkForFileTypes = () => {
    const numFragPipeFiles = Uploader.files.filter(file => file.label && file.label.includes(fragLabel)).length;
    const numGeneticsFiles = Uploader.files.filter(file => fileRegex.test(file.name.toLowerCase())).length;

    const totalValidFiles = numFragPipeFiles + numGeneticsFiles;

    Uploader.showCheckbox = totalValidFiles > 1;
    Uploader.showPairButton = numFragPipeFiles == 1 && numGeneticsFiles == 1;
    Uploader.showStitchButton = numFragPipeFiles == 0 && numGeneticsFiles >= 2;
  };

  this.handleFileSelection = () => {
    Promise.all(Uploader.files.map(file => {
      file.label = labelRemoteFile(file);

      return file;
    })).then(() => {
      $scope.$applyAsync(() => {
        this.checkForFileTypes();
      });
    }).catch(error => {
      $log.error("Error in labeling file:", error);
    });
  };

  this.handleRemoteFileSelection = () => {
    $scope.$applyAsync(() => {
      Uploader.files.map(file => {
        const label = labelRemoteFile(file);
        file.label = label;
      });

      this.checkForFileTypes();
    });
  };

  // TODO 2024-04-12 @akotlar add this back and make work with remote files
  this.uploadProteomicFiles = (files) => {
    return Promise.all([Uploader.generateUUID(), Uploader.generateUUID()]).then(([annotationUUID, proteomicsUUID]) => {
      const modifiedJobData = Object.assign({}, this.job, {
        annotationID: annotationUUID,
        proteomicsID: proteomicsUUID
      });

      const promise1 = Uploader.upload(files, null, modifiedJobData).then(response => {
        // TODO 2024-03-22 @akotlar Submit the combined genetic + proteomic dataset
        // rather than each dataset in a separate location? Do we want the combine?
        // TODO 2024-04-12 @akotlar fix the handling of Promise.allSettled
        return Promise.allSettled(uploadResponses.map(response => jobSubmit.submitAsync(response.data).then(job => {
          this.submitted.push(job);
          return job;
        })));
      }).catch((rejection) => {
        $log.debug('upload rejected', rejection);
      });

      fileUploadPromises.push(promise1);
    });
  };

  this.uploadLocalStitchedFiles = (files) => {
    return Uploader.generateUUID().then((uuid) => {
      return Promise.all(files.map(file => Uploader.uploadLocalFile(file, uuid)));
    }).then(uploadResponses => {
      const uuidSet = new Set();
      let inputFileNames = [];

      uploadResponses.forEach(uploadResponse => {
        $log.debug(uploadResponse);
        uuidSet.add(uploadResponse.uuid);
        inputFileNames.push(uploadResponse.fileName);
      });

      if (uuidSet.size != 1) {
        return Promise.reject(`Expected a single uuid, got ${uuidSet.size}`);
      }

      const uuid = uuidSet.values().next().value;

      if (!uuid) {
        return Promise.reject("No uuid found");
      }

      return fetch('/api/jobs/create', {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${userTokens.accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inputFileNames, uuid, job: this.job })
      }).then(response => response.json()).then(job => {
        return jobSubmit.submitAsync(job).then(submittedJob => {
          files.forEach(file => {
            file.submitted = true;
            file.jobId = submittedJob._id;
          });

          return submittedJob;
        });
      });
    }).catch((error) => {
      $log.error("Error in uploading stitched files:", error);

      files.forEach(file => {
        Uploader.setError(file, error);
      });

      return Promise.reject(error);
    });
  };


  this.uploadStitchedFiles = (files) => {
    if (files.length === 0) {
      return Promise.reject("Cannot stitch files of different types");
    }

    let stitchedFileType;

    for (const file of files) {
      const currentFileType = file.type === 's3' ? "s3" : "local";

      if (stitchedFileType && stitchedFileType != currentFileType) {
        Promise.reject("Cannot stitch files of different types");
      }

      stitchedFileType = currentFileType;
    }

    if (stitchedFileType !== "s3") {
      return this.uploadLocalStitchedFiles(files);
    }

    return Uploader.uploadCombinedRemoteFiles(files, this.job).then(job => {
      $log.debug("job", job);

      return jobSubmit.submitAsync(job).then(submittedJob => {
        files.forEach(file => {
          file.submitted = true;
          file.jobId = submittedJob._id;
        });

        return submittedJob;
      });
    });
  };

  this.uploadFiles = () => {
    // TODO: Add back support for proteomic files
    Uploader.running = true;
    Uploader.finished = false;
    const fileUploadPromises = [];
    this.allCancelled = false;

    if (Uploader.stitchedGeneticFiles && Uploader.stitchedGeneticFiles.length > 0) {
      try {
        const promise = this.uploadStitchedFiles(Uploader.stitchedGeneticFiles);
        fileUploadPromises.push(promise);
      } catch (err) {
        $log.error(err);
        alert(err);
        return;
      }
    }

    const remainingFiles = Uploader.files.filter(file => !this.isGeneticFileStitched(file));

    // TODO 2024-04-12 @akotlar Make remote/local file handling more general
    const remoteFiles = remainingFiles.filter(file => file.type === "s3");
    const localFiles = remainingFiles.filter(file => file.type !== "s3");

    if (remoteFiles.length > 0) {
      for (const remoteFile of remoteFiles) {
        const promise = uploadRemoteFile(remoteFile, this.job);
        fileUploadPromises.push(promise);
      }
    }

    if (localFiles.length > 0) {
      for (const localFile of localFiles) {
        const promise = uploadLocalFile(localFile, null, this.job);
        fileUploadPromises.push(promise);
      }
    }

    Promise.allSettled(fileUploadPromises).then((jobs) => {
      $log.debug("jobs", jobs);
      let submittedJobs = jobs.filter(job => job.status === "fulfilled").map(job => {
        if (job.value instanceof Array) {
          return job.value.filter(sjob => sjob.status === "fulfilled").map(sjob => sjob.value);
        }

        return job.value;
      }).flat();
      $log.debug("submittedJobs", submittedJobs);
      if (submittedJobs.length == 0) {
        return Promise.reject("No submissions succeeded");
      }

      // TODO 2024-04-12 @akotlar Add back auto-switching to the results page if there is a single job submitted
      // $rootScope.$broadcast(Uploader.allUploadedEvent, submittedJobs);
    }).catch((error) => {
      $log.error("Error in uploading files:", error);
    }).finally(() => {
      Uploader.running = false;

      // without this there is a race condition between .reset and
      // the finally function
      if (!this.allCancelled) {
        Uploader.finished = true;
      }

      if (Uploader.files.length == 0) {
        this.reset();
      }
    });
  };

  this.cancelUpload = (file) => {
    if (file.upload) {
      Uploader.cancelLocalFileUpload(file);
    }

    $scope.$applyAsync(() => {
      // If not uploading yet Uploader doesn't know about it
      const index = Uploader.files.indexOf(file);

      if (index > -1) {
        Uploader.files.splice(index, 1);

        if (Uploader.stitchedGeneticFiles.length) {
          const stitchedIndex = Uploader.stitchedGeneticFiles.indexOf(file);

          if (stitchedIndex > -1) {
            Uploader.stitchedGeneticFiles.splice(stitchedIndex, 1);
          }

          if (Uploader.stitchedGeneticFiles.length <= 1) {
            Uploader.isStitched = false;
            Uploader.stitchedGeneticFiles = [];
          }
        }

        if (Uploader.files.length > 0) {
          this.checkForFileTypes();
        } else {
          this.reset();
        }
      }
    });
  };

  this.cancelAllUploads = () => {
    this.allCancelled = true;
    // Without copyin the array, the array will get modified, and the foreach
    // Will prematurely stop
    Uploader.files.forEach((file) => {
      this.cancelUpload(file);
    });

    this.reset();
  };

  this.reset = () => {
    Uploader.cleanup();

    this.linkedFiles = [];
    Uploader.stitchedGeneticFiles = [];
    Uploader.isStitched = false;
    this.isPaired = false;
  };

  this.isUploading = () => {
    return Uploader.running;
  };

  this.isFinished = () => {
    return Uploader.finished;
  };

  //TODO: move this somewhere else
  this.showS3dialog = (ev) => {
    $mdDialog.show({
      controller: DialogController,
      // templateUrl: 'jobs/upload/jobs.upload.cloud.dialog.tpl.html',
      // Due to known angular material but, must wrap in md-dialog
      template: `
      <md-dialog flex="66">
          <sq-cloud-upload on-cancelled='cancel()' on-selected='save(files)'></sq-cloud-upload>
      </md-dialog>
      `,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true,
      fullscreen: true, //-xs and -sm breakpoints
    })
      // If files are provided, use them, if not, revert to the local files uploader
      .then((files) => {
        if (files) {
          this.queueAdditionalFiles(files);
        }
      })
      .catch((rejection) => {
        $log.error(rejection);
      });
  };

  function DialogController($scope, $mdDialog) {
    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.save = function (files) {
      $mdDialog.hide(files);
    };
  }

  this.openHelpDialog = () => {
    $mdDialog.show({
      controller: 'HelpDialogController',
      controllerAs: '$ctrl',
      templateUrl: 'jobs/upload/jobs.upload.help.dialog.tpl.html',
      clickOutsideToClose: true
    });
  };

  this.openExperimentDialog = () => {
    $mdDialog.show({
      controller: 'SubmitFormController',
      controllerAs: '$ctrl',
      templateUrl: 'jobs/upload/jobs.experiment.dialog.tpl.html',
      clickOutsideToClose: true,
      locals: {
        dataToPass: this.selectedExperiment,
        onComplete: (data_from_dialog_controller) => {
          this.selectedExperiment = data_from_dialog_controller;
        }
      },
      bindToController: true
    });
  };

  this.canPairFiles = () => {
    const fragpipeFiles = Uploader.files.filter(file => file.selected && file.label && file.label.includes(fragLabel));
    const geneticFiles = Uploader.files.filter(file => file.selected && fileRegex.test(file.name.toLowerCase()));

    return fragpipeFiles.length == 1 && geneticFiles.length == 1;
  };

  this.canStitchGeneticFiles = () => {
    const hasSelectedFragPipeFile = Uploader.files.some(file => file.selected && file.label && file.label.includes(fragLabel));
    const numSelectedGeneticFiles = Uploader.files.filter(file => file.selected && fileRegex.test(file.name.toLowerCase())).length;

    return !hasSelectedFragPipeFile && numSelectedGeneticFiles >= 2;
  };

  this.toggleAllFilesSelection = () => {
    Uploader.selectAllChecked = !Uploader.selectAllChecked;
    Uploader.files.forEach(file => {
      file.selected = Uploader.selectAllChecked;
    });
  };

  this.updateSelectAllState = () => {
    Uploader.selectAllChecked = Uploader.files.every(file => file.selected);
  };

  this.pairGeneProtein = () => {
    if (this.linkedFiles.length === 0) {
      this.linkedFiles = Uploader.files.filter(file => file.selected);
      this.isPaired = true;
    } else {
      this.linkedFiles = [];
      this.isPaired = false;
    }
  };

  this.stitchGeneticFiles = () => {
    if (Uploader.stitchedGeneticFiles.length === 0) {
      const filesToStitch = Uploader.files.filter(file => file.selected && fileRegex.test(file.name.toLowerCase()));

      const remoteFiles = filesToStitch.filter(file => file.type === "s3");
      const localFiles = filesToStitch.filter(file => file.type !== "s3");

      if (remoteFiles.length > 0 && localFiles.length > 0) {
        alert("Cannot currently combine remote and local files.\nPlease select files of the same type (one of either `Local` or `Remote`)");
        return;
      }

      if (filesToStitch.length >= 2) {
        Uploader.stitchedGeneticFiles = filesToStitch;
        Uploader.isStitched = true;
      }
    } else {
      Uploader.stitchedGeneticFiles = [];
      Uploader.isStitched = false;
    }
  };

  this.isFileLinked = (file) => {
    return this.linkedFiles.includes(file);
  };

  this.isGeneticFileStitched = (file) => {
    return Uploader.stitchedGeneticFiles.includes(file);
  };
}

function HelpDialogController($mdDialog) {
  this.closeDialog = function () {
    $mdDialog.hide();
  };
}

angular.module('sq.jobs.upload.component',
  ['sq.jobs.submit.service', 'sq.jobs.upload.service', 'sq.jobs.upload.cloud.component', 'ngFileUpload', 'sq.jobs.experiment.upload.service', 'sq.user.auth.tokens'])
  .factory('ExperimentUploader', ExperimentUploaderFactory)
  .controller('SubmitFormController', SubmitFormController)
  .controller('HelpDialogController', HelpDialogController)
  .component('sqUpload', {
    bindings: {
      backFunc: '&',
      title: '@',
      subTitle: '@',
      job: '<',
    },
    controller: UploadController,
    templateUrl: 'jobs/upload/jobs.upload.tpl.html',
    transclude: true,
  });
